
import { NgModule } from '@angular/core';

// Helpers
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { MatPaginatorIntl } from '@angular/material/paginator';
 import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
 import { RouterModule } from '@angular/router';
import { CustomPaginator } from '../helpers/CustomPaginatorConfiguration';
import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import { DemoMaterialModule } from '../helpers/material-modules';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { CommonModule } from '@angular/common';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@NgModule({
  declarations: [
    FileManagerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DemoMaterialModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    DemoMaterialModule,
    FileManagerComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ]
})
export class SharedModule { }
