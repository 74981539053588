
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/dicom-viewer',
    pathMatch: 'full'
  },
  {
    path: 'dicom-viewer',
    children: [
      { path: '', loadChildren: () => import('./features/dicom-viewer/dicom-viewer.module').then(m => m.DicomViewerModule) },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
